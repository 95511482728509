import React from "react";
//preklady
import { withNamespaces } from 'react-i18next';

const About=({t})=>{
  return(
    <div style={{border:"none","width":"80%",margin:"auto"}}>
    <div style={{fontSize:"35px",fontWeight:"bold"}}>{t('aboutN1')} ver. 1.2</div><br />

    <div style={{fontSize:"25px",fontWeight:"bold"}}>{t('aboutN2')}</div><br />
    <div style={{fontSize:"20px"}}>{t('aboutT10')}</div><br />

    <div style={{fontSize:"25px",fontWeight:"bold"}}>{t('aboutN3')}</div><br />
    <div style={{fontSize:"20px"}}>{t('aboutT20')}</div><br />

    <div style={{fontSize:"25px",fontWeight:"bold"}}>{t('aboutN4')}</div><br />
    <div style={{fontSize:"20px"}}>{t('aboutT30')}</div><br />

    </div>
  )
}

export default withNamespaces()(About);